import { SessionService } from './../../../services/session.service';
import { OfferService } from './../../../user/offers/offer.service';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TopService } from '../../../shared/services/top.service';
import { PageErrorService } from '../../../page-error/page-error.service';
import { ISplitOrderLinePost } from '../../interfaces/ISplitOrderLinePost';
import { IOrder } from '../../interfaces/IOrder';

import * as models from '../../interfaces/model';
import { UserService } from '../../../user/user.service';
import { SharedService } from '../../../shared/shared.service';
import { UtilityService } from '../../../../app/core/utility.service';
import { IOrderListCombo } from '../../interfaces/IOrderListCombo';
import { OrderStatusEnum } from '../../enums/orderStatusEnum';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { PDFDocument, rgb } from 'pdf-lib';

const PAGE_SIZE = 25;
@Injectable()
export class OrdersService {
  readonly defaultOrderRequest = `?PageNumber=1&PageSize=${PAGE_SIZE}`;
  restApiVerb = '';
  orderChosen: models.IOrder = {};
  orders: models.IOrder[] = [];
  selected_order_number: number;
  selected_doc_number: number;
  handleFirstOrderLoadRequired = false;
  orderLoaded = new EventEmitter<boolean>();
  orderLoaded2: boolean;
  isOrders = false;

  ordersErrorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };

  salesOrderStatusArray: models.SalesOrderStatus[];
  defaultStatusColor = '#E7E7E7';
  statusColorMap: { [key in models.SalesOrderStatus]?: { color: string; description: string } };
  selectedOrder: models.Order;

  constructor(
    private http: HttpClient,
    public topService: TopService,
    private pageErrorService: PageErrorService,
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService,
    private utils: UtilityService,
    private offerservice: OfferService,
    private sessionService: SessionService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe
  ) { }

  loadOrderHistory(): void {
    this.orders = [];
    this.isOrders = true;
    this.getOrders(this.defaultOrderRequest).subscribe(
      data => {
        this.orderLoaded2 = true;
        this.topService.loading = false;
        this.orderLoaded.emit(true);
        if (data) {
          data.values.forEach(order => {
            if (order.orderStatus === 'open') {
              order.orderStatus = 'Open';
            }
          });
          this.orders = data.values;
          if (this.orders.length === 0) {
            this.isOrders = false;
          }
        }
      },
      error => {
        this.topService.loading = false;
        this.pageErrorService.errorMessage = error.statusText;
        this.pageErrorService.errorCode = error.status;
        this.router.navigate(['page-error']);
      }
    );
  }

  splitLinePost(docEntry: number, lines: ISplitOrderLinePost): Observable<IOrder> {
    this.restApiVerb = 'orders/';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + docEntry + '/split';
    return this.http.put<IOrder>(url, lines);
  }

  cancelOrder(docEntry: number): Observable<number> {
    const url = environment.buyerPortalBaseUrl + 'orders/' + docEntry + '/cancellation';
    return this.http.put<number>(url, 'cancel');
  }

  updateOrder(orderNo): Observable<null> {
    this.restApiVerb = 'orders';
    const url = environment.buyerPortalBaseUrl + 'cart/fromOrder';
    return this.http.post<null>(url, orderNo);
  }

  getOrders(orderData: string = this.defaultOrderRequest): Observable<models.IOrderMain> {
    this.restApiVerb = 'orders';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + orderData;
    return this.http.get<models.IOrderMain>(url).pipe(
      map((orders: models.IOrderMain) => {
        if (orders) {
          orders.values.forEach((order) => {
            if (order.payment.status) {
              order.orderStatus = 'paid';
            }
          });
        }
        return orders;
      })
    );
  }

  getOpenOrders(orderData: string): Observable<models.IOrderMain> {
    this.restApiVerb = 'orders?status=open';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + '&' + orderData;
    return this.http.get<models.IOrderMain>(url);
  }



  chargeOrder(piid: string, amount: number): Observable<any> {
    const url = environment.buyerPortalBaseUrl + 'payments/' + piid + '/applied';
    const param = {
      finalAmount: amount,
    };
    return this.http.post(url, param);
  }

  toPascalCase(s: string): string {
    return this.utils.toTitleCase(s);
  }

  getExternalId(order: IOrder | models.Order) {
    if (!order.externalId) {
      return '--';
    } else if (order.payment?.id && ['Card_Pending', 'Pending Payment'].includes(order.externalId)) {
      return 'CC Paid';
    } else {
      return order.externalId;
    }
  }

  getOpenOrdersShort(docEntry = '') {
    const url = environment.buyerPortalBaseUrl + 'orders/open';
    return this.http.get<IOrderListCombo[]>(url)
      .pipe(
        map((orders) => {
          const openOrders = orders.filter((order) => order.docEntry !== +docEntry)
            .sort((o1, o2) => o2.docNumber - o1.docNumber);
          return openOrders;
        }
        ))
  }

  goToFullOrderPage(docEntry: string): void {
    this.topService.loading = true;
    this.getOrder(docEntry).subscribe(
      (order: models.Order) => {
        this.topService.loading = false;
        // this.orderChosen = order;
        this.selectedOrder = order;
        this.router.navigate(['/buyer/full-order', docEntry]);
      },
      (err) => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.ordersErrorModal);
      }
    );
  }

  getOrderDetails(docEntry: string): Observable<models.IOrder> {
    this.restApiVerb = 'orders/' + docEntry;
    const url = environment.buyerPortalBaseUrl + this.restApiVerb;
    return this.http.get<models.IOrder>(url).pipe(
      map(res => {
        if (res.payment.status) {
          res.orderStatus = 'paid';
        }
        if (res.orderDetails) {
          res.orderDetails.forEach(orderLine => {
            orderLine.description = orderLine.description ? orderLine.description : orderLine.itemName;
          });
        }
        return res;
      })
    );
  }

  getOrder(docEntry: string): Observable<models.Order> {
    this.restApiVerb = 'orders/v2/' + docEntry;
    const url = environment.buyerPortalBaseUrl + this.restApiVerb;
    return this.http.get<models.Order>(url).pipe(
      map((order) => {
        if (order.progressStatus === models.SalesOrderStatus.ProcessingStock) {
          order.progressStatus = models.SalesOrderStatus.Processing;
        }
        return order;
      })
    )
  }

  getOrderList(orderData: string = this.defaultOrderRequest): Observable<models.OrderListResponse> {
    this.restApiVerb = 'orders/v2';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + orderData;
    return this.http.get<models.OrderListResponse>(url).pipe(
      map((response) => {
        if (response.values) {
          response.values = response.values.map(order => {
            if (order.progressStatus === models.SalesOrderStatus.ProcessingStock) {
              order.progressStatus = models.SalesOrderStatus.Processing;
            }
            return order;
          });
        }
        return response;
      })
    );
  }

  filterAndMapSalesOrderStatus() {
    const userRole = this.sessionService.userRole;
    const filteredSalesOrderStatus = Object.values(models.SalesOrderStatus).filter(
      (status) => typeof status === 'number' && status !== models.SalesOrderStatus.ProcessingStock
    );
    this.salesOrderStatusArray = filteredSalesOrderStatus as models.SalesOrderStatus[];

    this.statusColorMap = {
      [models.SalesOrderStatus.PendingPayment]: { color: '#EB3E43', description: 'Pending payment' },
      [models.SalesOrderStatus.PaymentApproved]: { color: '#F37428', description: 'Payment Approved' },
      [models.SalesOrderStatus.ProcessingTransit]: { color: '#FBA50F', description: 'Processing Transit' },
      [models.SalesOrderStatus.Processing]: { color: '#E9C30C', description: 'Processing' },
      [models.SalesOrderStatus.Ready]: { color: '#B4CC28', description: 'Ready' },
      [models.SalesOrderStatus.Shipped]: { color: '#7ED342', description: 'Shipped' }
    };
  }

  getStatusProgressWidth(order: models.Order, pdf = false): string {
    var result = '';
    var inputStatus = order.progressStatus;
    var numberOfCells = Object.keys(models.SalesOrderStatus).length / 2;
    var position = inputStatus;

    numberOfCells--;
    if (inputStatus >= models.SalesOrderStatus.ProcessingStock) {
      position--;
    }

    var cellWidth = 100 / numberOfCells;
    switch (position) {
      case 1:
        result = '0%';
        break;

      case numberOfCells:
        result = pdf ? '99.2%' : 'calc(100% - 9px)';
        break;

      default:
        result = `${((position - 1 / 2) * cellWidth).toFixed(2)}%`;
        break;
    }

    return result;
  }


  replaceBindings(html: string, bindings: any, order: models.Order): string {
    for (const key in bindings) {
      const regex = new RegExp(`{{${key}}}`, 'g');
      const value = bindings[key] ?? ''; // avoid to print null
      html = html.replace(regex, value);
    }

    if (bindings.progressWidth) {
      const widthRegex = /width:\s*0%/g; // search for 'width: 0%' to replace it
      html = html.replace(widthRegex, `width: ${bindings.progressWidth}`);
    }

    let rows = '';
    bindings.orderDetails.forEach(item => {
      const rowTemplate = `
      <tr style= "page-break-inside: avoid;">
        <td style="border-top:1px solid #e5e5e5;padding: 9px 5px 9px 0px;font-size: 14px;line-height:21px;">
          {{itemCode}}
        </td>
        <td style="border-top:1px solid #e5e5e5;padding: 9px 33px 9px 0px;font-size: 14px;line-height:21px;">
          {{description}}
        </td>
        <td style="border-top:1px solid #e5e5e5;padding: 9px 0;text-align:left;font-size: 14px;line-height:21px;">
          {{exwPoint}}
        </td>
        <td style="border-top:1px solid #e5e5e5;padding: 9px 0;text-align:right;font-size: 14px;line-height:21px;">
          {{unitPrice}}
        </td>
        <td style="border-top:1px solid #e5e5e5;padding: 9px 0;text-align:right;font-size: 14px;line-height:21px;">
          {{quantity}}
        </td>
        <td style="border-top:1px solid #e5e5e5;padding: 9px 0;text-align:right;font-size: 14px;line-height:21px;">
          {{total}}
        </td>
      </tr>
      `;

      let row = rowTemplate
        .replace(/{{itemCode}}/g, item.itemCode)
        .replace(/{{description}}/g, item.description)
        .replace(/{{exwPoint}}/g, item.exwPoint)
        .replace(/{{unitPrice}}/g, this.currencyPipe.transform(item.unitPrice, 'USD', 'symbol-narrow'))
        .replace(/{{quantity}}/g, item.quantity)
        .replace(/{{total}}/g, this.currencyPipe.transform((item.unitPrice * item.quantity), 'USD', 'symbol-narrow'));

      rows += row;
    });
    html = html.replace('{{orderDetailsRows}}', rows);


    var template = `
                                    <tr>
                                        <td style='width: 16.66%; text-align: left; font-size: 14px; line-height: 21px; font-weight: ${order.progressStatus === models.SalesOrderStatus.PendingPayment ? 'bold' : 'normal'};'>
                                            Pending Payment
                                        </td>
                                        <td style='width: 16.66%; text-align: center; font-size: 14px; line-height: 21px;' font-weight: ${order.progressStatus === models.SalesOrderStatus.PaymentApproved ? 'bold' : 'normal'};'>
                                            Payment Approved
                                        </td>
                                        <td style='width: 16.66%; text-align: center; font-size: 14px; line-height: 21px;' font-weight: ${order.progressStatus === models.SalesOrderStatus.ProcessingTransit ? 'bold' : 'normal'};'>
                                            Processing Transit
                                        </td>
                                        <td style='width: 16.66%; text-align: center; font-size: 14px; line-height: 21px;' font-weight: ${order.progressStatus === models.SalesOrderStatus.Processing ? 'bold' : 'normal'};'>
                                            Processing
                                        </td>
                                        <td style='width: 16.66%; text-align: center; font-size: 14px; line-height: 21px;' font-weight: ${order.progressStatus === models.SalesOrderStatus.Ready ? 'bold' : 'normal'};'>
                                            Ready
                                        </td>
                                        <td style='width: 16.66%; text-align: right; font-size: 14px; line-height: 21px;' font-weight: ${order.progressStatus === models.SalesOrderStatus.Shipped ? 'bold' : 'normal'};'>
                                            Shipped
                                        </td>
                                    </tr>
    `;
    html = html.replace('{{statusesTitle}}', template);

    if (!order.payment.id) {
      var startIndex = html.indexOf('<div id="termsAndConditionsPage"');
      if (startIndex > 0) {
        html = html.substring(0, startIndex);
      }
    }

    return html;
  }

  openPdf(e: Event, docEntry: number): void {
    if (e) { e.stopPropagation(); }
    this.topService.loading = true;
    this.http.get('assets/order-template.html', { responseType: 'text' }).subscribe((html) => {
      this.getOrder(docEntry.toString()).subscribe(
        (order: models.Order) => {
          let orderPdfBindings: any = {
            statusDescription: this.statusColorMap[order.progressStatus].description,
            orderNumber: order.docNumber,
            orderDate: this.datePipe.transform(order.orderDate, 'shortDate'),
            orderDueDate: order.dueDate ? this.datePipe.transform(order.dueDate, 'shortDate') : '—',
            customerNumber: order.cardCode,
            customerRefNumber: (order.payment && order.payment.id) ? "CC Paid" : "—",
            progressWidth: this.getStatusProgressWidth(order, true),
            billingAddress: order.billingAddress,
            shippingAddress: order.shippingAddress,
            salesEmployee: order.salesEmployee,
            contactName: order.contactName,
            contactEmail: order.contactEmail,
            canceled: order.isCancelled ? 'Yes' : 'No',
            orderDetails: order.orderDetails,
            bankName: this.userService.orderBankInfo?.bankName || '',
            bankAddress: this.userService.orderBankInfo?.address || '',
            bankCity: this.userService.orderBankInfo?.city || '',
            bankStateProvince: this.userService.orderBankInfo?.stateProvince || '',
            bankCountry: this.userService.orderBankInfo?.country || '',
            bankZipCode: this.userService.orderBankInfo?.zipCode || '',
            bankRoutingNumber: this.userService.orderBankInfo?.routingNumber || '',
            bankAch: this.userService.orderBankInfo?.ach || '',
            bankSwiftBicCode: this.userService.orderBankInfo?.swiftBicCode || '',
            bankAccountNumber: this.userService.orderBankInfo?.accountNumber || '',
            bankIntermediaryBIC: this.userService.orderBankInfo?.intermediaryBIC || '',
            bankBeneficiaryName: this.userService.orderBankInfo?.beneficiaryName || '',
            bankBeneficiaryAddress: this.userService.orderBankInfo?.beneficiaryAddress || '',
            bankBeneficiaryCity: this.userService.orderBankInfo?.beneficiaryCity || '',
            bankBeneficiaryStateProvince: this.userService.orderBankInfo?.beneficiaryStateProvince || '',
            bankBeneficiaryCountry: this.userService.orderBankInfo?.beneficiaryCountry || '',
            bankBeneficiaryZipCode: this.userService.orderBankInfo?.beneficiaryZipCode || '',
            subtotal: this.currencyPipe.transform(order.orderDetails.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0), 'USD', 'symbol-narrow'),
            convenienceFee: this.currencyPipe.transform(order?.payment?.fee, 'USD', 'symbol-narrow'),
            discount: this.currencyPipe.transform(order.orderDiscountAmount, 'USD', 'symbol-narrow'),
            freight: this.currencyPipe.transform(order.shippingCost, 'USD', 'symbol-narrow'),
            tax: this.currencyPipe.transform(order.tax, 'USD', 'symbol-narrow'),
            orderTotal: this.currencyPipe.transform(order.orderTotal, 'USD', 'symbol-narrow'),
            comments: order.comments,
            customerName: order.cardName,
            paymentDate: this.datePipe.transform(order.payment.orderDate, 'mediumDate'),
            electronicAuthorizedAgent: `${order.payment.firstName || ''} ${order.payment.lastName || ''}`,
            time: this.datePipe.transform(order.payment.orderDate, 'H:mm'),
            ip: order.payment.ipAddress,
            cardLast4Digits: order.payment.cardLast4Digits,
            authorizedAmount: this.currencyPipe.transform(order.payment.amount, 'USD', 'symbol-narrow')
          };

          if (order.payment.id) {
            this.http.get(`assets/images/order/${order.payment.cardType}.svg`, { responseType: 'text' })
              .subscribe(svgContent => {
                const base64SVGCardType = 'data:image/svg+xml;base64,' + btoa(svgContent);
                orderPdfBindings.cardImageUrl = base64SVGCardType;
                this.generatePdf(html, orderPdfBindings, order);
              },
                (err) => { this.topService.loading = false; });
          } else {
            this.generatePdf(html, orderPdfBindings, order);
          }
        },
        (err) => {
          this.topService.loading = false;
          this.sharedService.handleBuyerHttpError(err, this.ordersErrorModal);
        });
    });
  }

  generatePdf(htmlContent: string, bindings: any, order: models.Order): void {
    var htmlContent = this.replaceBindings(htmlContent, bindings, order);
    const url = environment.html2pdfWebFunction;
    const headers = {
      'Content-Type': 'text/plain',
      Accept: 'application/pdf'
    };

    this.http.post(
      url,
      htmlContent,
      { headers: headers, responseType: 'blob' }
    )
      .subscribe(async (response) => {
        this.topService.loading = false;
        const pdfBytes = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);

        const totalPages = pdfDoc.getPageCount();
        pdfDoc.getPages().forEach((page, index) => {
          const { width, height } = page.getSize();
          const fontSize = 10;
          const text = `Page ${index + 1} of ${totalPages}`;

          const textWidth = text.length * fontSize * 0.5;
          const xPosition = width - textWidth - 25;

          page.drawText(text, {
            x: xPosition,
            y: 20,
            size: fontSize,
            color: rgb(0, 0, 0),
          });
        });

        const modifiedPdfBytes = await pdfDoc.save();
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `HUBX-order-${bindings.orderNumber}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      },
        (err) => {
          this.topService.loading = false;
        });
  }





}
